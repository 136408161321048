.menuAside {
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* max-width: 270px !important; */
}

.XMLItemsContainer {
  margin-bottom: 16px;
  display: flex;
  flex-wrap: wrap;
}

.list {
  width: 100%;
  max-height: 500px;
  overflow-y: auto;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 10px;
}

.listImg {
  height: 100px;
  max-width: 100px;
  object-fit: cover;
}

.listText {
  max-height: 100px;
  overflow-y: auto;
}

@media screen and (max-width: 850px) {
  .menuAside {
    max-width: 100% !important;
  }
}
