@font-face {
  font-family: "Metrisch";
  src: url("assets/fonts/Metrisch-Medium-webfont.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Oriole display";
  src: url("assets/fonts/Oriole-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "By Note";
  src: url("assets/fonts/By-Note.ttf") format("truetype");
}

@font-face {
  font-family: "CA saygon";
  src: url("assets/fonts/CASaygonText-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Hando";
  src: url("assets/fonts/Hando-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
