.menuAside {
  padding: 16px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.formFieldSmall {
  width: 50%;
  max-width: 150px;
}

.modelBoxContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 500px;
  overflow: auto;
  margin-bottom: 24px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 10px;
}

.modelBox {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.imgModel {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
