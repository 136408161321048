.gridFlex {
  display: flex;
  align-items: flex-start;
}

.xmlName {
  margin-left: 8px;
  white-space: nowrap;
  max-width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
}


.pre {
  display: inline-block;
  white-space: pre;
  margin: 1em 0px;
  font-weight: 300;
  width: 100%;

  white-space: -moz-pre-wrap; /* Mozilla, supported since 1999 */
  white-space: -pre-wrap; /* Opera */
  white-space: -o-pre-wrap; /* Opera */
  white-space: pre-wrap; /* CSS3 - Text module (Candidate Recommendation) http://www.w3.org/TR/css3-text/#white-space */
  word-wrap: break-word; /* IE 5.5+ */
}