.itemList:hover {
  filter: brightness(70%);
}

.imageListContainer {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
}

.itemListItem {
  cursor: pointer;
}

.imageTemplateColor {
  width: 100%;
  height: 100%;
}

.imageDescriptionIcon {
  color: rgba(232, 224, 224, 0.54) !important;
}
