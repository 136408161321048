.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 90%;
  transform: translate(-50%, -50%);
  min-width: 40%;
  background-color: white !important;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  overflow-y: auto;
}

.modalHeader {
  /* display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 50px; */
  padding: 10px 10px 0 10px;
}
.modelContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 100%;
}

.menuAside {
  /* padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
}

.formFieldSmall {
  width: 50%;
  max-width: 150px;
}

.modelBoxTemplateOptions {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.modelBoxContainer {
  display: flex;
  /* justify-content: center; */
  width: 100%;
  height: 100%;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  padding: 10px;
  overflow-y: auto;
}

.modelBox {
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.imgModel {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
