.menuFixed {
  background: white;
  border-radius: 10px 10px 0 0;
  margin-bottom: 4px;
}

.dynamicMenu {
  background: white;
  border-radius: 0 0 10px 10px;
  min-height: 63px;
  overflow-x: auto;
}
.dynamicMenuItems {
  align-items: center;
  /* flex-wrap: wrap; */
}

.canvasContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  overflow-x: auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  padding: 15px;
  margin: 8px 0;
  /* min-height: 440px; */
}

.canvasContainerItemBox {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  overflow-x: auto;
  /* max-height: 440px; */
}

.canvasContainerItemBox {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px !important;
  overflow-x: auto;
  /* max-height: 440px; */
}

.canvasCloneContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  max-width: 20px !important;
  max-height: 20px !important;
  z-index: -1;
  overflow: hidden;
}
